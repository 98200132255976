@import '../settings/index';

.quote-box {
  height: 150px;
  position: absolute;
  top: 50%;
  margin-top: -200px;

  #carouselExampleControls {

    @include breakpoint(medium) {
      margin-left: -70px;
    }

    @include breakpoint(large) {
      margin-left: -115px;
    }
  }

  .carousel-inner {
    height: 160px;

    @include breakpoint(large) {
      height: 140px;
    }

    .carousel-caption {
      top: 50%;
      padding-top: 0;
      padding-bottom: 0;

      @include breakpoint(large) {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      p {
        text-align: center;
        font-style: italic;

        @include breakpoint(medium) {
          text-align: left;
        }
      }
    }
  }

  .button-action-container {
    display: flex;
    justify-content: center;

    @include breakpoint(medium) {
      display: block;
    }
  }
}
