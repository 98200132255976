@import "settings/index";

html,
body {
  height: 100%;
}

.form-control {
  border: 1px solid $blue;
  height: 50px;

  &:focus {
    border: 1px solid $blue;
  }

  &::placeholder {
    color: #898c9d;
    padding-left: 10px;
  }
}

.btn-primary {
  color: $color-white !important;
}

html {
  scroll-behavior: smooth;
}

.rounded-max {
  border-radius: 30px;
}

.max_h-100 {
  height: 100vh;
}

.max_h-70 {
  height: 80vh;
}

.bg-picture {
  background-size: cover;
  background: url("/img/bg-main.jpg") no-repeat center;
}

.border-btn {
  border-color: #7B7B7B;
  padding: 10px 25px;
  border-radius: 15px;
}

.font-light {
  font-weight: 300;
}

.gray-color {
  color: #757575;
}

.color-gray2 {
  color: #515151;
}

.color-gray3 {
  color: #818181;
}

.color-gray3 p {
  color: #818181;
}

.color-gray4 {
  color: #4b4b4b;
}

.phone-nav {
  font-weight: 500;
  font-size: 18px;
  color: #565656;
}

.phone-nav-under {
  font-size: 11.5px;
  color: #8b8b8b;
}

.eng-btn {
  color: $color-white;
  background: #35b6ff;
  border-radius: 10px;
  max-height: 50px;
  max-width: 50px;
  padding: 10px;
}

.eng-btn:hover {
  text-decoration: none;
  color: #daedff;
}

.container1 {
  position: relative;
}

.color-blue {
  //color: #35b6ff;
  color: #149cea;
}

.font-weight-black {
  font-weight: 800;
}

.lh-27 {
  line-height: 27px;
}

.lh-17 {
  line-height: 18px;
}

.lh-16 {
  line-height: 16px;
}

.fs-53 {
  font-size: 53px;
}

.fs-23 {
  font-size: 23px;
}

.fs-18 {
  font-size: 18px;
}

.fs-33 {
  font-size: 33px;
}

.fs-10 {
  font-size: 10px;
}

.radius-btn {
  border-radius: 15px;
}

.mt_minus_150 {
  margin-top: -70px;
}

.max-h-450 {
  max-height: 450px;
}

.bg-lightblue {
  background: #F5FCFF;
}

.bg-lightblue-2 {
  background: #E7F8FF;
}

.font-weight-medium {
  font-weight: 500;
}

.rounded-topr {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.rounded-bottomr {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.myshadow {
  box-shadow: 0 4px 45px rgba(0, 0, 0, 0.075);
  border-radius: 10px;
}

.bg-blue {
  background: #35B6FF;
}

.underline {
  text-decoration: underline;
  color: $color-white;
}

.custom-remove {
  display: none;
}

.custom-remove2 {
  display: unset;
}

.mw-550 {
  max-width: 550px;
}

.myshadow2 {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);

}

.myborder {
  border: 1px solid #f1f9ff;
  transition: 0.3s;
}

.myborder:hover {
  border: 1px solid $blue;
}

.text-d:hover {
  text-decoration: none!important;
}

.cutomcarousel li {
  border: none;
  background: #ccc;
}

.cutomcarousel li.active {
  background: $blue;
}
.sizeimg {
  height: 250px;
  width: 300px;
  object-fit: cover;
}

.h-200px {
  height: 260px;
  object-fit: cover;
}

.h-400px {
  height: 460px;
  object-fit: cover;
}

.nav-tabs .nav-link.active {
  color: $color-white !important;
  background: $blue;
}

.service-item {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  max-width: 200px;
  margin: 0 auto;
  border-radius: 15px;
}

@media (max-width: 992px) {
  .custom-remove {
    display: block;
  }

  .custom-remove2 {
    display: none;
  }

  .max_h-70 {
    max-height: 90vh;
  }

  .fs-53 {
    font-size: 31px;
  }

  .fs-23 {
    font-size: 20px;
  }

  .text-center-c {
    text-align: center;
  }
}
