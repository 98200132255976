$color-white: #fff;
$blue: #35b6ff;

$input-focus-border-color: #719b3a;

// RWD breakpoints
$breakpoints: (
  small: 576px,
  medium: 768px,
  large: 992px,
  xlarge: 1200px
);
